import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa, faCcMastercard, faCcDiscover, faCcJcb, faCcDinersClub, faCcAmex } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

const CreditCard = ({ cardNumber, cardHolder, cardValidate, cardCvv, cardBrand }) => {
    const cardBrandIcon = brand => {
        switch(brand?.toLowerCase()) {
            case 'visa':
                return faCcVisa;
            case 'mastercard':
                return faCcMastercard;
            case 'discover':
                return faCcDiscover;
            case 'jcb':
                return faCcJcb;
            case 'dinersclub':
                return faCcDinersClub;
            case 'amex':
                return faCcAmex;
            default:
                return faCreditCard;
        }
    }
    return (
        <div className="flex justify-center items-center">
            <div className="w-96 h-56 m-auto bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform">
                <img className="relative object-cover w-full h-full rounded-xl" src="https://i.imgur.com/Zi6v09P.png" /> 
                <div className="w-full px-8 absolute top-8">
                    <div className="flex justify-between">
                        <div className="">
                            <p className="font-light">
                                Nome
                            </p>
                            <p className="font-medium tracking-widest uppercase">
                                {cardHolder || "Nome do Titular"}
                            </p>
                        </div>
                        <FontAwesomeIcon className='w-12 h-12' icon={cardBrandIcon(cardBrand)} />
                    </div>
                    <div className="pt-1">
                        <p className="font-light">
                            Numero
                        </p>
                        <p className="font-medium tracking-more-wider">
                            {cardNumber || "0000 0000 0000 0000"}
                        </p>
                    </div>
                    <div className="pt-6 pr-6">
                        <div className="flex justify-between">
                            <div className="">
                                <p className="font-light text-xs text-xs">
                                    Validade
                                </p>
                                <p className="font-medium tracking-wider text-sm">
                                    {cardValidate || "00/00"}
                                </p>
                            </div>

                            <div className="">
                                <p className="font-light text-xs">
                                    CVV
                                </p>
                                <p className="font-bold tracking-more-wider text-sm">
                                    {cardCvv || "000"}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
   
export default CreditCard;