const Navbar = () => {
    return (
        <div style={{ backgroundColor: "#008591" }}>
            <div className="container mx-auto">
            <img
                src="https://admin.metalife.com.br/imagens/logo.png"
                alt="Metalife Pilates"
                className="w-40 p-2"
            />
            </div>
        </div>
    )
}

export default Navbar;