export const getNetErrosTable = {
  antifraud: {
    'fraud': "Transação negada por regra de segurança do sistema de Antifraude",
    '200': "Transação negada por regra de segurança do sistema de Antifraude",
    '250': "Transação negada por regra de segurança do sistema de Antifraude",
    '0700': "Transação negada por regra de segurança do sistema de Antifraude",
    '2000': "Transação negada por regra de segurança do sistema de Antifraude",
    '480': "Transação negada por regra de segurança do sistema de Antifraude",
    '481': "Transação negada por regra de segurança do sistema de Antifraude",
    '0150': "Transação enviada com o campo 'amount' preenchido com o valor nulo ou inválido",
    '0902': "Transação enviada com o campo 'required' preenchido com o valor nulo ou inválido",
    '101': "Transação enviada com o campo 'required' preenchido com o valor nulo ou inválido",
    '102': "Transação enviada com o campo 'required' preenchido com o valor nulo ou inválido",
    '231': "Dados do cartão de crédito preenchidos incorretamente",
    '0400': "Dados do cartão de crédito preenchidos incorretamente",
    '202': "Dados do cartão de crédito preenchidos incorretamente",
    '400': "Emissor do cartão de crédito não aprovou a transação.",
    '0330': "Emissor do cartão de crédito não aprovou a transação.",
    '0500': "Emissor do cartão de crédito não aprovou a transação.",
    '0600': "Emissor do cartão de crédito não aprovou a transação.",
    '1300': "Emissor do cartão de crédito não aprovou a transação.",
    '0800': "Emissor do cartão de crédito não aprovou a transação.",
    '234': "Indisponibilidade no sistema Antifraude . Contate a GetNet.",
    '0901': "Indisponibilidade no sistema Antifraude . Contate a GetNet.",
    '0000': "Indisponibilidade no sistema Antifraude . Contate a GetNet.",
    '0903': "Indisponibilidade no sistema Antifraude . Contate a GetNet.",
    '150': "Indisponibilidade no sistema Antifraude . Contate a GetNet.",
    '151': "Indisponibilidade no sistema Antifraude . Contate a GetNet.",
    '121': "Indisponibilidade no sistema Antifraude . Contate a GetNet.",
    '500': "Indisponibilidade no sistema Antifraude . Contate a GetNet.",
  }
};