import InputMask from "react-input-mask";

const FormInput = ({
  formData,
  setFormData,
  label,
  field,
  mask,
  onKeyUp,
  type = "text",
  step,
  max,
  min,
  className,
}) => (
  <div className="mb-2">
    <label className="block text-sm font-medium text-gray-700 font-medium mb-2">
      {label}
    </label>
    <InputMask
      className={`mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${className}`}
      type={type}
      mask={mask}
      onKeyUp={onKeyUp}
      value={formData?.[field]}
      onChange={(e) => setFormData({ ...formData, [field]: e.target.value })}
      step={type === "number" ? step : null}
      max={type === "number" ? max : null}
      min={type === "number" ? min : null}
      maskPlaceholder={""}
    />
  </div>
);

export default FormInput;
