import { useCallback, useEffect, useState } from "react";
import FormInput from "./components/layout/FormInput";
import GetnetPromisor from "./getnet/GetnetPromisor";

import * as creditCardType from "credit-card-type";
import CreditCard from "./components/CreditCard";
import Navbar from "./components/NavBar";
import SuccessfulPayment from "./components/SuccessfulPayment";
import FormMonetaryInput from "./components/layout/FormMonetaryInput";
import Modal from "./components/layout/Modal";
import SelectInput from "./components/layout/SelectInput";

import axios from "axios";
import DisplayDataCustomer from "./components/DisplayDataCustomer";
import FormDataAddress from "./components/FormDataAddress";
import FormDataCustomer from "./components/FormDataCustomer";
import { getNetErrosTable } from "./getnet/getNetErrosTable";

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

const installmentsOptions = [
  { value: 1, label: "1x" },
  { value: 2, label: "2x" },
  { value: 3, label: "3x" },
  { value: 4, label: "4x" },
  { value: 5, label: "5x" },
  { value: 6, label: "6x" },
  { value: 7, label: "7x" },
  { value: 8, label: "8x" },
  { value: 9, label: "9x" },
  { value: 10, label: "10x" },
  { value: 11, label: "11x" },
  { value: 12, label: "12x" },
  { value: 13, label: "13x" },
  { value: 14, label: "14x" },
  { value: 15, label: "15x" },
  { value: 16, label: "16x" },
  { value: 17, label: "17x" },
  { value: 18, label: "18x" },
  { value: 19, label: "19x" },
  { value: 20, label: "20x" },
  { value: 21, label: "21x" },
];

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const orcamento_id = urlParams.get("orcamento_id");

  const [client, setClient] = useState();
  const [paymentSuccessParams, setPaymentSuccessParams] = useState();
  const [updatingDataCustomer, setUpdatingDataCustomer] = useState(true);
  const [loading, setLoading] = useState(true);
  const [clientIp, setClientIp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorModal, setErrorModal] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Confirmar pedido");
  const [successPayed, setSuccessPayed] = useState(false);
  const [formDataCustomer, setFormDataCustomer] = useState({
    first_name: "",
    last_name: "",
    document_type: "CPF",
    document_number: "",
    phone_number: "",
    observation: "",
    card_number: "",
    email: "",
  });
  const [formDataAddress, setFormDataAddress] = useState({
    street: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
    postal_code: "",
    country: "Brasil",
  });
  const [formDataOrder, setFormDataOrder] = useState({
    order_id: uuidv4(),
    sales_tax: "",
    product_type: "service",
  });
  const [formDataCredit, setFormDataCredit] = useState({
    delayed: false,
    pre_authorization: false,
    save_card_data: false,
    transaction_type: "FULL",
    number_installments: 1,
    soft_descriptor: "Compra Metalife Pilates",
  });
  const [formDataCard, setFormDataCard] = useState({
    number_token: null,
    cardholder_name: "",
    security_code: "",
    brand: "",
    card_validate: "",
  });
  const [formDataPayment, setFormDataPayment] = useState({
    amount: null,
    currency: "BRL",
  });

  const getClientIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setClientIp(res.data.ip);
  };

  useEffect(() => {
    //Adiciona script do antifraude
    const randomDecimal = Math.random();
    const randomSessionID = Math.floor(randomDecimal * 10000000000);
    setSessionId(randomSessionID);
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=" +
      randomSessionID;
    document.head.appendChild(script);
    getClientIp();
  }, []);

  useEffect(() => {
    const fetchCliente = async () => {
      const response = await fetch(
        `https://admin.metalife.com.br/api/v1/orcamento/dados-via-orcamento?orcamento_id=${orcamento_id}`
      );
      const dataJson = await response.json();

      setClient(dataJson.data);

      if (dataJson.success) {
        setFormDataCustomer((prevState) => ({
          ...prevState,
          first_name: dataJson.data.first_name,
          last_name: dataJson.data.last_name,
          email: dataJson.data.email,
          phone_number: dataJson.data.phone_number,
          document_number: dataJson.data.document_number,
        }));
        setFormDataPayment((prevState) => ({
          ...prevState,
          amount: dataJson.data.amount,
        }));
        setFormDataAddress((prevState) => ({
          ...prevState,
          street: dataJson.data.street,
          number: dataJson.data.number,
          complement: dataJson.data.complement,
          district: dataJson.data.district,
          city: dataJson.data.city,
          state: dataJson.data.state,
          postal_code: dataJson.data.postal_code,
        }));
        setUpdatingDataCustomer(false);
      }

      setLoading(false);
    };

    fetchCliente();
    return () => {};
  }, []);

  const setCardType = useCallback(() => {
    const card_number = cleanMask(formDataCustomer.card_number);
    if (card_number.length < 16) return false;

    const cardType = detectCardType(card_number);
    setFormDataCard((prevState) => ({ ...prevState, brand: cardType }));
  }, [formDataCustomer.card_number]);

  const enviar = async () => {
    setErrorModal("");
    setErrorMessage("");
    let dataCustomer = formDataCustomer;
    dataCustomer.document_number = cleanMask(dataCustomer.document_number);
    dataCustomer.phone_number = cleanMask(dataCustomer.phone_number);
    dataCustomer.card_number = cleanMask(dataCustomer.card_number);

    let dataCard = formDataCard;
    let [expiration_month, expiration_year] = dataCard.card_validate.split("/");
    dataCard.expiration_month = expiration_month;
    dataCard.expiration_year = expiration_year;

    if (!dataCustomer?.first_name) {
      setErrorMessage("Nome é obrigatório!");
      return;
    }

    if (!dataCustomer?.last_name) {
      setErrorMessage("Sobrenome é obrigatório!");
      return;
    }

    if (!dataCustomer?.email) {
      setErrorMessage("Email é obrigatório!");
      return;
    }

    if (!dataCustomer?.phone_number) {
      setErrorMessage("Celular é obrigatório!");
      return;
    }

    if (formDataPayment.amount < 10) {
      setErrorMessage(
        "Valor da compra é obrigatório e deve ser pelo menos 10 reais!"
      );
      return;
    }

    if (
      !(
        formDataCredit.number_installments >= 1 &&
        formDataCredit.number_installments <= 21
      )
    ) {
      setErrorMessage("Número de parcelas inválido!");
      return;
    }

    if (!dataCustomer?.card_number) {
      setErrorMessage("Número do cartão é obrigatório!");
      return;
    }

    if (!dataCard?.cardholder_name) {
      setErrorMessage("Nome no cartão é obrigatório!");
      return;
    }

    if (!(expiration_month >= 1 && expiration_month <= 12)) {
      setErrorMessage("Data de expiracao de cartão de credito invalida!");
      return;
    }

    if (expiration_year < new Date().toDateString().slice(-2)) {
      setErrorMessage("Data de expiracao de cartão de credito invalida!");
      return;
    }

    if (!dataCard?.security_code || dataCard.security_code.length < 3) {
      setErrorMessage("Código de segurança inválido!");
      return;
    }

    if (
      !dataCustomer?.document_number ||
      !validaCpfCnpj(dataCustomer.document_number)
    ) {
      setErrorMessage("CPF ou CNPJ inválido!");
      return;
    }

    if (dataCustomer.document_number?.length === 11) {
      dataCustomer.document_type = "CPF";
    } else {
      dataCustomer.document_type = "CNPJ";
    }

    setButtonLabel("Pegando token...");
    // Pega token
    const getToken = await GetnetPromisor.getToken();
    dataCustomer.token = getToken.dados.access_token;

    setButtonLabel("Buscando cliente...");
    // Busca cliente
    const paramsGetCustomer = {
      document_number: dataCustomer.document_number,
      token: dataCustomer.token,
    };
    const getCustomer = await GetnetPromisor.getCustomer(paramsGetCustomer);

    if (getCustomer.dados.message) {
      setErrorMessage(getCustomer.dados.message);
      return;
    }
    let customer_id;
    if (getCustomer.dados.total === 0) {
      setButtonLabel("Criando cliente...");
      const createCustomer = await GetnetPromisor.createCustomer(dataCustomer);
      customer_id = createCustomer.dados.customer_id;
    } else {
      customer_id = getCustomer.dados.customers[0].customer_id;
    }

    // Geraq token cartão
    const paramsCardToken = {
      card_number: dataCustomer.card_number,
      token: dataCustomer.token,
    };

    setButtonLabel("Gerando token cartão...");
    const getCardToken = await GetnetPromisor.getCardToken(paramsCardToken);

    if (getCardToken.dados.message) {
      setErrorMessage(getCardToken.dados.message);
    }

    const cardToken = getCardToken.dados.number_token;
    let paramsAddress = formDataAddress;
    paramsAddress.postal_code = cleanMask(paramsAddress.postal_code);

    const shippings = {
      phone_number: dataCustomer.phone_number,
      address: {
        street: paramsAddress.street,
        number: paramsAddress.number,
        complement: paramsAddress.complement,
        city: paramsAddress.city,
        state: paramsAddress.state,
        country: paramsAddress.country,
        postal_code: paramsAddress.postal_code,
      },
    };

    const paramsPayment = {
      ...formDataPayment,
      amount: formatAmount(formDataPayment.amount),
      order: formDataOrder,
      customer: {
        ...dataCustomer,
        document_type:
          dataCustomer.document_number.length === 11 ? "CPF" : "CNPJ",
        customer_id,
        billing_address: paramsAddress,
      },
      device: {
        device_id: sessionId,
        ip_address: clientIp,
      },
      credit: {
        ...formDataCredit,
        number_installments: parseInt(formDataCredit.number_installments),
        transaction_type:
          formDataCredit.number_installments > 1
            ? "INSTALL_NO_INTEREST"
            : "FULL",
        card: {
          ...dataCard,
          number_token: cardToken,
        },
      },
      token: dataCustomer?.token,
    };

    const createPayment = await GetnetPromisor.createPayment(paramsPayment);

    if (createPayment?.dados?.payment_id) {
      setSuccessPayed(true);
      setPaymentSuccessParams(paramsPayment);
    } else {
      setButtonLabel("Confirmar pedido");
      if (
        createPayment.dados.details &&
        createPayment?.dados?.details.length > 0
      ) {
        let errorMessage =
          createPayment?.dados?.details[0]?.description +
          ":  " +
          createPayment?.dados?.details[0]?.description_detail;

        if (createPayment?.dados?.details[0]?.antifraud?.status_code) {
          errorMessage =
            createPayment?.dados?.details[0]?.antifraud?.description +
            ":  " +
            getNetErrosTable.antifraud[
              createPayment.dados.details[0].antifraud.status_code
            ];
        }

        setErrorModal(errorMessage);
      } else if (createPayment.dados.message) {
        setErrorModal(createPayment.dados.message);
      } else {
        alert("Erro ao realizar pagamento!");
      }
    }
  };

  const formatAmount = (value) => {
    return value * 100;
  };

  const validaCpfCnpj = (val) => {
    if (val?.length === 11) {
      return validarCPF(val);
    } else {
      return validarCNPJ(val);
    }
  };

  const validarCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, ""); // Remove caracteres não numéricos

    if (cpf.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais (invalido)
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    var Soma;
    var Resto;
    var i;
    Soma = 0;
    if (cpf == "00000000000") return false;

    for (i = 1; i <= 9; i++)
      Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(cpf.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(cpf.substring(10, 11))) return false;
    return true;
  };

  const validarCNPJ = (cnpj) => {
    cnpj = cnpj?.replace(/[^\d]/g, ""); // Remove caracteres não numéricos
    if (cnpj?.length !== 14) {
      return false;
    }

    // Verifica se todos os dígitos são iguais (invalido)
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false;
    }

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) {
      return false;
    }

    return true;
  };

  if (successPayed) {
    const price = formDataPayment.amount;
    const installments = formDataCredit.number_installments;
    return (
      <SuccessfulPayment
        price={price}
        installments={installments}
        paymentSuccessParams={paymentSuccessParams}
      />
    );
  }

  if (loading)
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );

  return (
    <div>
      {errorModal !== "" && (
        <Modal message={errorModal} setErrorModal={setErrorModal} />
      )}
      <Navbar />
      <div className="container mx-auto px-4">
        <h1 className="font-bold text-xl my-4 mb-0">
          Pague em até 21x sem juros!
        </h1>
        <p className="mb-4">
          Valor destinado exclusivamente para a compra dos equipamentos
          comercializados pela MetaLife Pilates.
        </p>
        {errorMessage !== "" && (
          <Alert
            message={errorMessage}
            onClose={() => {
              setErrorMessage("");
            }}
          />
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {!updatingDataCustomer && (
            <DisplayDataCustomer
              formDataAddress={formDataAddress}
              formDataCustomer={formDataCustomer}
              setUpdatingDataCustomer={setUpdatingDataCustomer}
            />
          )}
          {updatingDataCustomer && (
            <>
              <FormDataCustomer
                setFormDataCustomer={setFormDataCustomer}
                formDataCustomer={formDataCustomer}
              />

              <FormDataAddress
                setFormDataAddress={setFormDataAddress}
                formDataAddress={formDataAddress}
              />
            </>
          )}
          <div className="shadow overflow-hidden rounded-md py-2 px-4 bg-gray-50 border mb-4">
            <h2 className="font-semibold text-xl mt-0">Dados da compra</h2>
            <hr className="my-1" />

            {!client && (
              <>
                {" "}
                <p className="mb-2">
                  Insira abaixo o valor negociado com o seu consultor.
                </p>
                <p className="mb-2 text-red-500">
                  Use ponto (.) para separar os centavos.
                </p>
              </>
            )}
            <div className="grid grid-cols-3 gap-4">
              {!client && (
                <FormMonetaryInput
                  label="Valor da compra"
                  setFormData={setFormDataPayment}
                  formData={formDataPayment}
                  field={"amount"}
                />
              )}
              {client && client.amount && (
                <div>
                  <div>Valor:</div>
                  <div className="text-xl mt-2">
                    {client.amount.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </div>
                </div>
              )}
              <SelectInput
                options={installmentsOptions}
                label="Parcelas"
                setFormData={setFormDataCredit}
                formData={formDataCredit}
                field={"number_installments"}
              />
            </div>
          </div>
        </div>

        <div className="shadow overflow-hidden rounded-md py-2 px-4 bg-gray-50 border mb-4">
          <h2 className="font-semibold text-xl mt-0">Dados do cartão</h2>
          <hr className="my-1" />
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-2">
              <div className="grid grid-cols-2 gap-4">
                <FormInput
                  label="Numero do cartão"
                  setFormData={setFormDataCustomer}
                  formData={formDataCustomer}
                  field={"card_number"}
                  mask={"9999 9999 9999 9999"}
                  onKeyUp={setCardType}
                />
                <FormInput
                  label="Bandeira"
                  setFormData={setFormDataCard}
                  formData={formDataCard}
                  field={"brand"}
                />
                <div className="col-span-2">
                  <FormInput
                    label="Nome no cartão"
                    setFormData={setFormDataCard}
                    formData={formDataCard}
                    field={"cardholder_name"}
                    className={"uppercase"}
                  />
                </div>
                <FormInput
                  label="Validade"
                  setFormData={setFormDataCard}
                  formData={formDataCard}
                  field={"card_validate"}
                  mask={"99/99"}
                />
                <FormInput
                  label="Código de segurança"
                  setFormData={setFormDataCard}
                  formData={formDataCard}
                  field={"security_code"}
                  mask={"9999"}
                />
              </div>
            </div>
            <div className="col-span-2">
              <CreditCard
                cardNumber={formDataCustomer.card_number}
                cardHolder={formDataCard.cardholder_name}
                cardValidate={formDataCard.card_validate}
                cardCvv={formDataCard.security_code}
                cardBrand={formDataCard.brand}
              />
            </div>
          </div>
        </div>

        <button
          className="flex items-center mb-4 cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => enviar()}
          disabled={buttonLabel !== "Confirmar pedido"}
        >
          {buttonLabel}
          {buttonLabel !== "Confirmar pedido" && <Spiner />}
        </button>
      </div>
    </div>
  );
}

const cleanMask = (value) => {
  return value?.replace(/\D/g, "");
};

const Alert = ({ message, onClose }) => {
  setTimeout(() => {
    onClose();
  }, 5000);

  return (
    <div
      class="fixed bottom-0 left-0 w-full flex items-center p-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
      role="alert"
    >
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <svg
            class="flex-shrink-0 inline w-4 h-4 mr-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span class="sr-only">Info</span>
          <div class="">{message}</div>
        </div>
        <button
          type="button"
          onClick={onClose}
          class="ml-auto -mx-1.5 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
          data-dismiss-target="#alert-1"
          aria-label="Close"
        >
          <span class="sr-only">Close</span>
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const Spiner = () => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className="w-4 h-4 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  );
};

const detectCardType = (number) => {
  const result = creditCardType(number);
  return result[0]?.niceType;
};

export default App;
