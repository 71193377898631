const DisplayDataCustomer = ({
  formDataAddress,
  formDataCustomer,
  setUpdatingDataCustomer,
}) => {
  return (
    <div className="shadow overflow-hidden rounded-md py-2 px-4 bg-gray-50 border mb-4">
      <h2 className="font-semibold text-xl mt-0">Dados do cliente</h2>
      <hr className="my-1" />
      <b>Nome:</b> {formDataCustomer.first_name}
      <br />
      <b>Sobrenome:</b> {formDataCustomer.last_name}
      <br />
      <b>Documento:</b> {formDataCustomer.document_number}
      <br />
      <b>Celular:</b> {formDataCustomer.phone_number}
      <br />
      <b>E-mail:</b> {formDataCustomer.email}
      <br />
      <b>Endereço:</b> {formDataAddress.street}, {formDataAddress.number} -{" "}
      {formDataAddress.district}, {formDataAddress.city}/{formDataAddress.state}
      ,{" CEP: "}
      {formDataAddress.postal_code} <br />
      <button
        className="flex items-center mb-4 cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
        onClick={() => setUpdatingDataCustomer(true)}
      >
        Editar
      </button>
    </div>
  );
};

export default DisplayDataCustomer;
