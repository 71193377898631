import InputMask from "react-input-mask";

const FormMonetaryInput = ({
  formData,
  setFormData,
  label,
  field,
  mask,
  onKeyUp,
  type = "text",
  step,
  max,
  min,
  className,
}) => {
  const formatValue = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };
  return (
    <div className="mb-2">
      <label className="block text-sm font-medium text-gray-700 font-medium mb-2">
        {label}
      </label>
      <InputMask
        className={`mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${className}`}
        type={"number"}
        mask={mask}
        onKeyUp={onKeyUp}
        value={formData?.[field]}
        onChange={(e) => formatValue(field, e.target.value)}
      />
    </div>
  );
};
export default FormMonetaryInput;
