import { useCallback } from "react";
import FormInput from "./layout/FormInput";

const FormDataAddress = ({ setFormDataAddress, formDataAddress }) => {
  const cleanMask = (value) => {
    return value?.replace(/\D/g, "");
  };

  const buscaCep = async (cep) => {
    const url = `https://viacep.com.br/ws/${cep}/json/`;
    const response = await fetch(url);
    const data = await response.json();

    return {
      postal_code: data.cep,
      street: data.logradouro,
      district: data.bairro,
      city: data.localidade,
      state: data.uf,
    };
  };

  const setAddressInformation = useCallback(async () => {
    const postal_code = cleanMask(formDataAddress.postal_code);
    if (postal_code.length < 8) return false;

    const address = await buscaCep(postal_code);
    setFormDataAddress((prevState) => ({ ...prevState, ...address }));
  }, [formDataAddress.postal_code]);

  return (
    <div className="shadow overflow-hidden rounded-md py-2 px-4 bg-gray-50 border mb-4">
      <h2 className="font-semibold text-xl mt-0">Endereço do cliente</h2>
      <hr className="my-1" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormInput
          label="CEP"
          setFormData={setFormDataAddress}
          formData={formDataAddress}
          field={"postal_code"}
          mask={"99999-999"}
          onKeyUp={setAddressInformation}
        />

        <FormInput
          label="Rua"
          setFormData={setFormDataAddress}
          formData={formDataAddress}
          field={"street"}
        />
        <FormInput
          label="Número"
          setFormData={setFormDataAddress}
          formData={formDataAddress}
          field={"number"}
        />
        <FormInput
          label="Complemento"
          setFormData={setFormDataAddress}
          formData={formDataAddress}
          field={"complement"}
        />
        <FormInput
          label="Bairro"
          setFormData={setFormDataAddress}
          formData={formDataAddress}
          field={"district"}
        />
        <FormInput
          label="Cidade"
          setFormData={setFormDataAddress}
          formData={formDataAddress}
          field={"city"}
        />
        <FormInput
          label="Estado"
          setFormData={setFormDataAddress}
          formData={formDataAddress}
          field={"state"}
        />
      </div>
    </div>
  );
};

export default FormDataAddress;
