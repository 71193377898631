import FormInput from "./layout/FormInput";

const FormDataCustomer = ({ setFormDataCustomer, formDataCustomer }) => {
  return (
    <div className="shadow overflow-hidden rounded-md py-2 px-4 bg-gray-50 border mb-4">
      <h2 className="font-semibold text-xl mt-0">Dados do cliente</h2>
      <hr className="my-1" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormInput
          label="Nome"
          setFormData={setFormDataCustomer}
          formData={formDataCustomer}
          field={"first_name"}
        />
        <FormInput
          label="Sobrenome"
          setFormData={setFormDataCustomer}
          formData={formDataCustomer}
          field={"last_name"}
        />
        <FormInput
          label="Documento"
          setFormData={setFormDataCustomer}
          formData={formDataCustomer}
          field={"document_number"}
          type={"number"}
        />
        <FormInput
          label="Celular"
          setFormData={setFormDataCustomer}
          formData={formDataCustomer}
          field={"phone_number"}
          mask={"(99) 99999-9999"}
        />
        <FormInput
          label="E-mail"
          setFormData={setFormDataCustomer}
          formData={formDataCustomer}
          field={"email"}
        />
      </div>
    </div>
  );
};

export default FormDataCustomer;
