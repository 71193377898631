import Axios from "axios";

const baseUrl = "https://backend.metalife.com.br/api";

const GetnetPromisor = {
  getToken: () => {
    return new Promise((resolve, reject) => {
      Axios.get(baseUrl + "/getnet/getToken")
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getCustomer: (params) => {
    return new Promise((resolve, reject) => {
      Axios.get(baseUrl + "/getnet/getCustomer", { params })
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createCustomer: (params) => {
    return new Promise((resolve, reject) => {
      Axios.post(baseUrl + "/getnet/createCustomer", params)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getCardToken: (params) => {
    return new Promise((resolve, reject) => {
      Axios.post(baseUrl + "/getnet/getCardToken", params)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createPayment: (params) => {
    return new Promise((resolve, reject) => {
      Axios.post(baseUrl + "/getnet/createPayment", params)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default GetnetPromisor;
