const SelectInput = ({
  options,
  formData,
  setFormData,
  label,
  field,
  className,
}) => (
  <div className="mb-2">
    <label className="block text-sm font-medium text-gray-700 font-medium mb-2">
      {label}
    </label>
    <select
      className={`mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${className}`}
      value={formData?.[field]}
      onChange={(e) => setFormData({ ...formData, [field]: e.target.value })}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default SelectInput;
