import moment from "moment";
import Navbar from "./NavBar";

const SuccessfulPayment = ({ price, installments, paymentSuccessParams }) => {
  console.log(paymentSuccessParams);
  return (
    <>
      <Navbar />
      <div className="container mx-auto mt-20">
        <div class="bg-gray-50">
          <div class="bg-gray-100 p-6  md:mx-auto">
            <svg
              viewBox="0 0 24 24"
              class="text-green-600 w-16 h-16 mx-auto my-6"
            >
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
            <div class="text-center">
              <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">
                Seu pagamento foi aprovado!
              </h3>
              <p class="text-gray-600 my-2">Obrigado por comprar conosco.</p>
              <div className="">
                <table className="mx-auto text-left table-auto min-w-max bg-white drop-shadow rounded-md text-sm">
                  <tr className="">
                    <td className="p-2 border-b border-gray-500">Data</td>
                    <td className="p-2 border-b border-gray-500 text-gray-600">
                      {moment().format("DD/MM/YYYY HH:mm")}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-b border-gray-500">Valor</td>
                    <td className="p-2 border-b border-gray-500 text-gray-600">
                      {price.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-b border-gray-500">Parcelas</td>
                    <td className="p-2 border-b border-gray-500 text-gray-600">
                      {installments}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border-b border-gray-500">Nome</td>
                    <td className="p-2 border-b border-gray-500 text-gray-600">
                      {paymentSuccessParams?.customer?.first_name}{" "}
                      {paymentSuccessParams?.customer?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2">Cartão</td>
                    <td className="p-2 text-gray-600">
                      **** **** ****{" "}
                      {paymentSuccessParams?.customer?.card_number?.slice(-4)}
                    </td>
                  </tr>
                </table>
              </div>

              <div class="py-10 text-center">
                <a
                  href="https://metalifepilates.com.br/"
                  class="px-12 bg-primary-600 hover:opacity-80 text-white font-semibold py-3 rounded-md"
                >
                  Ir para o site da MetaLife
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessfulPayment;
